/* =======
 * General
 * ======= */
.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit !important;
}

button {
  background: none;
  border: none;
}

/* =======
 * Navbar
 * ======= */
.navbar-brand {
  @media (min-width: 991px) {
    height: 100%;
  }

  img.navbar-icon {
    height: 130%;
    margin-top: -7px;
    margin-left: 0;
  }
}

.navbar-nav {
  width: 100%;
  justify-content: flex-end;

  .nav-link.nav-link:last-child {
    padding-right: 0;
  }
}

.navbar-icon {
  height: 20px;
  margin-top: -3px;
}

/* =======
 * Map
 * ======= */
.map-container {
  position: relative;
  width: 100%;
  flex-grow: 1;
}

/* =======
 * All Markers
 * ======= */
.cluster-marker,
.story-marker {
  transition: all 0.3s;

  svg {
    width: 36px;
    height: 36px;
    fill: rgb(164, 126, 0);
    transition: all 0.3s;
  }

  &:hover svg {
    fill: rgb(220, 169, 1);
  }

  .point-count {
    position: absolute;
    top: 13px;
    left: 13px;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
  }
}

/* =======
 * Cluster Marker
 * ======= */
.cluster-marker {
  cursor: pointer;

  svg {
    width: 26px;
    height: 26px;
    box-shadow: 0 0 7px 2px rgb(164, 126, 0);
    border-radius: 50%;

    &:hover {
      box-shadow: 0 0 10px 4px rgb(220, 169, 1);
    }
  }
}

/* ======================
 * Story Marker and Popup
 * ====================== */
.story-marker {
  position: absolute;
  transform: translate(-50%, -90%);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.1) translate(-45%, -85%);
    z-index: 1;
  }

  .preview {
    &.hidden {
      display: none;
    }

    .popover-body {
      padding: 10px;
    }

    &.bs-popover-top {
      top: unset;
      bottom: 100%;

      .arrow {
        margin-bottom: 1px;
        margin-left: 9px;
      }
    }

    &.bs-popover-bottom {
      top: 100%;

      .arrow {
        margin-left: 9px;
      }
    }

    &.left {
      left: unset;
      right: calc(100% - 35px);

      .arrow {
        margin-left: calc(100% - 24px);
      }
    }

    .brief {
      width: 120px;
      margin: 5px 0 -5px;
      font-size: 12px;
      line-height: 1.3;
      text-align: center;
    }
  }
}

/* ===========
 * Story Panel
 * =========== */
.story-panel {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: stretch;
  z-index: 2;

  .main-content {
    max-width: 440px;
    padding: 20px;
    background-color: white;
    overflow-y: auto;

    img.profile {
      width: 100%;
      background-color: #ccc;
    }

    .information {
      display: flex;
      justify-content: space-between;
      font-weight: bold;

      &:first-of-type {
        margin-top: 10px;
      }

      > span:last-child {
        text-align: right;
      }
    }

    .detailed {
      margin-top: 10px;
      text-align: justify;
    }

    .notes {
      margin-top: 10px;
      text-align: justify;
      font-style: italic;
    }
  }

  .story-nav {
    display: flex;
    position: absolute;
    width: 80px;
    height: 100%;
    align-items: flex-end;
    padding-bottom: 15px;
    opacity: 0.1;

    &:hover {
      opacity: 0.2;
    }

    &-next {
      right: 0;
    }
  }

  .close-button {
    position: absolute;
    right: 25px;
    top: 25px;
    border: none;
    background: white;
    border-radius: 100px;
    padding: 10px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);

    > svg {
      display: block;
    }
  }
}

/* =======
 * Our Story
 * ======= */
.our-story {
  padding-top: 20px;
}

.our-story,
.project {
  p {
    font-weight: 300;
    text-align: justify;
  }

  img {
    max-width: 100%;
  }
}

.quote {
  display: flex;
  align-items: center;

  blockquote {
    margin: 20px 0;
    padding: 0 20px;
    border: 5px solid #eee;
    border-top: 0;
    border-bottom: 0;
    text-align: justify;
  }

  &-name {
    font-size: x-large;

    @media (max-width: 575px) {
      display: none;
    }
  }
}

/* =======
 * Contact Us
 * ======= */

.contact .container {
  margin-top: 20px;
}

/*
 * OLD
 */
/* =======
 * General
 * ======= */
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Josefin Sans', sans-serif;
}

/* =======
 * Custom Navbar Styling
 * ======= */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar .container {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .navbar-brand {
    font-size: x-large;
    white-space: nowrap;
  }
}
@media (max-width: 500px) {
  .navbar-brand {
    font-size: large;
  }
}
.navbar-brand img {
  display: inline;
  height: 150%;
  margin-top: -10px;
  margin-right: 9px;
  margin-left: -2px;
}
@media (max-width: 768px) {
  .navbar-brand img {
    height: 100%;
    margin-top: -9px;
  }
}

.navbar-trail {
  font-size: 25px;
  line-height: 30px;
  display: block;
  float: left;
  padding-top: 15px;
  font-weight: 300;
  display: none;
}
@media screen and (max-width: 1000px) {
  .navbar-trail {
    display: none;
  }
}

.navbar-default .navbar-nav li a {
  font-size: medium;
}

#navFacebook a {
  margin: -2px 0 0;
}
#navFacebook a img {
  height: 21px;
}

/* =======
 * Cover image and text
 * ======= */
.pretty-text {
  z-index: 6;
  margin: -150px auto;
  width: 800px;
  max-width: 90%;
  font-size: 45px;
  position: relative;
  top: -50vh;
  text-align: center;
  color: white;
  font-weight: 300;
  font-family: Oswald, sans-serif;
}
@media (max-width: 700px) {
  .pretty-text {
    top: -55vh;
    font-size: 33px;
  }
}
@media (max-width: 500px) {
  .pretty-text {
    font-size: 27px;
  }
}
